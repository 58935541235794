import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/gatsby";
import Agent from "../../Interfaces/agent";
import Contacts, { LoanOfficer } from "../../Interfaces/contacts";
import Customer from "../../Interfaces/customer";
import instance from "../../utils/Axios";

export interface EmailCommunicationObject {
  to: string[];
  body: string;
  subject: string;
  cc: string[];
  bcc: string[];
}

export interface SMSCommunicationObject {
  to: string[];
  body: string;
}

// //
// Async Thunk Actions
// //
export const getCustomerFromId = createAsyncThunk<Customer, string | number>(
  "communications/getCustomerFromId",
  async customerId => {
    try {
      const response = await instance.get(`contacts/customer/${customerId}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);

export const getCustomerHubspotInfo = createAsyncThunk<any, string | number>(
  "communications/getCustomerHubspotInfo",
  async id => {
    try {
      const response = await instance.get(`/contacts/hubspot-contact/${id}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);

export const getAgentFromId = createAsyncThunk<Agent, string | number>(
  "communications/getAgentFromId",
  async agentId => {
    try {
      const response = await instance.get(`/contacts/api/agent/${agentId}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);

export const getContactsFromId = createAsyncThunk<Contacts, string | number>(
  "communications/getContactsFromId",
  async dealId => {
    try {
      const response = await instance.get(`/deals/${dealId}/contacts/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);

export const sendCommunicationEmailToContacts = createAsyncThunk<
  any,
  EmailCommunicationObject
>(
  "communications/sendCommunicationEmailToContacts",
  async communicationobject => {
    try {
      const response = await instance.post(
        `/communications/conversations/email/`,
        {
          to: communicationobject.to,
          subject: communicationobject.subject,
          body: communicationobject.body,
          cc: communicationobject.cc,
          bcc: communicationobject.bcc,
        }
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);

export const sendCommunicationSMSToContacts = createAsyncThunk<
  any,
  SMSCommunicationObject
>(
  "communications/sendCommunicationSMSToContacts",
  async communicationobject => {
    try {
      const response = await instance.post(
        `/communications/conversations/sms/`,
        {
          to: communicationobject.to,
          body: communicationobject.body,
        }
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);

export const getLoanOfficerFromId = createAsyncThunk<LoanOfficer, string>(
  "communications/getLoanOfficerFromId",
  async id => {
    try {
      const response = await instance.get(`/contacts/loan-officer/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const wrappedError: any = error;
      return { error: true, data: wrappedError.response.data };
    }
  }
);
