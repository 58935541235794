import { createSlice } from "@reduxjs/toolkit";
import { createDeal, getDealFromId } from "./actions";

const initialState = {
  loading: false,
  hasErrors: false,
  errors: null,
  deal: null,
  successfullyCreatedDeal: false,
};

const dealSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    resetDealStates(state) {
      state.successfullyCreatedDeal = false;
      state.errors = null;
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getDealFromId.pending, state => {
      state.loading = true;
    });
    builder.addCase(getDealFromId.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.loading = false;
      state.deal = payload;
    });
    builder.addCase(getDealFromId.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    builder.addCase(createDeal.pending, state => {
      state.loading = true;
    });
    builder.addCase(createDeal.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.errors) {
        state.hasErrors = true;
        state.errors = payload.errors;
      } else {
        state.hasErrors = false;
        state.successfullyCreatedDeal = true;
      }
    });
    builder.addCase(createDeal.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
  },
});

export const { resetDealStates, setErrors } = dealSlice.actions;

export default dealSlice.reducer;
