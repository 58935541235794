import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/gatsby";
import Agent from "../../Interfaces/agent";
import Customer from "../../Interfaces/customer";
import instance from "../../utils/Axios";

export interface DataSenderObject {
  count: number;
  next: string | null;
  previous: string | null;
  results: DataSenderModel[];
}

export interface DataSenderProps {
  vid: string;
  status: string;
  working_with_clever: boolean;
  notes: string;
  agent_address: string;
  agent_brokerage_name: string;
  agent_city: string;
  agent_email: string;
  agent_name: string;
  agent_phone: string;
  agent_state: string;
  agent_zipcode: string;
}

export interface DataSenderModel {
  id: number;
  request_body: any;
  query_parameters: any;
  name: string;
  security_object_type: string;
  security_object_id: number;
  url: string;
  method: string;
  request_body_format: string;
  created_at: string;
  updated_at: string;
  mortgage_partner: number;
}

export interface HubspotData {
  acquisition_type: string;
  ae_owner: string;
  agent_conversion_source: string;
  agent_home_base: string;
  agent_notes: string;
  agent_profile_link: string;
  agent_status: string;
  agent_tags: string;
  agent_tier: string;
  blanket_referral_agreement_link: string;
  brokerage: string;
  buyer_commission_model: string;
  buyer_is_prequalified: string;
  buyer_showing_time: string;
  buyer_would_like_to_prequalify: string;
  buying_intent: string;
  buying_market: string;
  buying_price_high: string;
  buying_price_low: string;
  closedate: string;
  commission_models_accepted: string;
  company: string;
  connection_owner: string;
  contact_type: string;
  conversion_source: string;
  customer_tags: string;
  email: string;
  expected_list_date: string;
  experiment_segment: string;
  external_vendor_id: string;
  firstname: string;
  gclid: string;
  home_value: string;
  hs_analytics_source: string;
  hs_calculated_phone_number: string;
  hs_lead_status: string;
  hubspot_owner_id: string;
  hunter_id: string;
  hunter_notes: string;
  internal_notes: string;
  is_click_to_claim: string;
  is_cold_mortgage_customer: string;
  is_in_lead_jail: string;
  is_mashvisor: string;
  is_text_opt_out: string;
  lastname: string;
  lead_confidence_score: string;
  lead_jail_notes: string;
  lead_jail_reason: string;
  lead_segment: string;
  lead_type: string;
  lending_bank: string;
  listing_address: string;
  listing_link: string;
  loan_office_preferred_contact_method: string;
  load_officer_tags: string;
  max_num_agents: string;
  mortgage_partner_lead_status: string;
  needs_sales_support: string;
  net_revenue: string;
  owned_property_in_last_three_years: string;
  phone: string;
  preferred_appointment_date: string;
  preferred_appointment_time: string;
  property_type: string;
  property_usage: string;
  qualifies_for_opendoor_offer: string;
  recent_update: string;
  referral_agreement_status: string;
  seller_commission_model: string;
  showing_address: string;
  special_requests: string;
  text_consent_opt_in: string;
  tm_notes: string;
  unit_number: string;
  vendor: string;
}

export interface DataSenderDataModel {
  hubspot: HubspotData;
  customer: Customer;
  agent: Agent;
}

export interface MPUpdatePostModel {
  contactId: number;
  deal_id?: number;
  status: string;
  notes: string;
}

export interface MPUpdateStatusRequestParams {
  contactId: number | string;
  dealId?: number | string | undefined;
}

export const sendData = createAsyncThunk<any, DataSenderProps>(
  "dataSender/sendData",
  async props => {
    try {
      const response = await instance.post(`contacts/data-sender/`, {
        ...props,
      });

      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const wrappedErr: any = err;
      if (wrappedErr.data && wrappedErr.data.messages) {
        return {
          error: true,
          messages: wrappedErr.data.messages,
        };
      }
      return {
        error: true,
        messages: ["Failed sending data to data sender"],
      };
    }
  }
);

// GET CUSTOMER DATA SENDER INFO
export const getCustomerDataSender = createAsyncThunk<
  DataSenderModel | any,
  number
>("dataSender/getCustomerDataSender", async id => {
  try {
    const response = await instance.get(`contacts/customer/${id}/data-sender/`);

    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    return {
      error: true,
      messages: [
        "Error fetching customer data sender, you can select the data sender manually",
      ],
    };
  }
});

// GET CUSTOMER DATA SENDER DATA
export const getCustomerDataSenderData = createAsyncThunk<
  DataSenderDataModel | any,
  number
>("dataSender/getCustomerDataSenderData", async id => {
  try {
    const response = await instance.get(
      `contacts/customer/${id}/data-sender/data/`
    );

    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    return {
      error: true,
      messages: ["Failed requesting customer data sender information."],
    };
  }
});

export const sendCustomerDataSender = createAsyncThunk<
  DataSenderProps | any,
  any
>("dataSender/sendCustomerDataSender", async props => {
  try {
    const response = await instance.post(
      `/contacts/customer/${props.vid}/data-sender/send/?data_sender_id=${props.data_sender_id}`,
      {
        ...props.data,
      }
    );

    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    const wrappedErr: any = err;
    if (wrappedErr.data && wrappedErr.data.messages) {
      return {
        error: true,
        messages: wrappedErr.data.messages,
      };
    }
    return {
      error: true,
      messages: [
        "There was an error sending the request. Please try again, or contact dev support.",
      ],
    };
  }
});

export const getAllDataSenders = createAsyncThunk<
  DataSenderObject | any,
  number | null
>("dataSender/getAllDataSenders", async page => {
  try {
    if (page) {
      const response = await instance.get(
        `contacts/data-senders/?page=${page}`
      );
      return response.data;
    }
    const response = await instance.get(`/contacts/data-senders/`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return {
      error: true,
      messages: ["There was an error fetching all data senders"],
    };
  }
});

export const getMPUpdateOptions = createAsyncThunk<
  string[],
  MPUpdateStatusRequestParams
>("dataSender/getMPUpdateOptions", async params => {
  try {
    const { dealId, contactId } = params;
    let query = "";
    if (dealId) {
      query = `?deal_id=${dealId}`;
    }
    const response = await instance.get(
      `/contacts/${contactId}/mp-updates/${query}`
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    const typedError = error as any;
    let ErrorMessage = "There was an error fetching the mp update options";
    if (
      typedError &&
      typedError.response &&
      typedError.response.status === 404
    ) {
      ErrorMessage =
        "The mp status options could not be found. The contact may not be a valid mp contact.";
    }
    if (
      typedError &&
      typedError.response &&
      typedError.response.status === 400
    ) {
      if (typedError.response.data) {
        ErrorMessage = Object.keys(typedError.response.data)
          .map(key => `${key}: ${typedError.response.data[key].toString()}`)
          .toString();
      } else {
        ErrorMessage =
          "Contact is not able to send updates. Invalid MP Contact";
      }
    }
    return {
      error: true,
      messages: [ErrorMessage],
    };
  }
});

// Get Deals from contact
export const getDealsFromContact = createAsyncThunk<any, number>(
  "dataSender/getDealsFromContact",
  async vid => {
    try {
      const response = await instance.get(`/contacts/deals/${vid}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: true,
        messages: ["There was an error fetching deals from the contact"],
      };
    }
  }
);

// Pass Deal into this
// {custmer.firstname} {customer.lastname} - {deal_type} ({deal_id})
export const postMPUpdate = createAsyncThunk<any, MPUpdatePostModel>(
  "dataSender/postMPUpdate",
  async props => {
    const requestProps = {
      status: props.status,
      notes: props.notes,
      deal_id: props.deal_id,
    };
    try {
      const response = await instance.post(
        `/contacts/${props.contactId}/mp-update/`,
        {
          ...requestProps,
        }
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: true,
        messages: ["There was an error posting the mp update"],
      };
    }
  }
);
