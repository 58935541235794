import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/gatsby";
import instance from "../../utils/Axios";

export interface CreateDealProps {
  // Default Props
  customer: number; // Customer Id
  hubspot_owner_id: number; // Current User Id
  home_value: number;
  property_type: string;
  lead_confidence_score: number;
  listing_link: string;
  max_num_agents: number;
  conversion_source: string;
  internal_notes: string;
  preferred_appointment_date: string;
  preferred_appointment_time: string;
  // Buyer Props
  loan_officer?: number;
  buyer_commission_model_id?: number;
  buying_price_high?: number;
  buying_price_low?: number;
  buying_market_string?: string;
  // Seller Props
  seller_commission_model_id?: number;
  zestimate?: number;
  listing_address_string?: string;
}

export const CommissionModels = {
  "Flat Fee Model": 1,
  "1% Model": 2,
  "Under 125k Model": 3,
  "Under 75k Model": 4,
  "Buyer No Rebate Model": 5,
  "Traditional Model": 6,
  "Buyer 1k Rebate Model": 7,
  "Buyer 1% Rebate Model": 8,
  "Buyer Tiered Rebate Model": 9,
  "Clever Cash Back (0.5%)": 10,
  "Clever Cash Back ($250)": 11,
};

export const getDealFromId = createAsyncThunk<any, string | number>(
  "deal/getDealById",
  async dealId => {
    const response = await instance.get(`/deals/${dealId}/`);

    return response.data;
  }
);

export const createDeal = createAsyncThunk<any, CreateDealProps>(
  "deal/createDeal",
  async deal => {
    try {
      const response = await instance.post(`/deals/create_deal/`, {
        ...deal,
      });

      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const wrappedErr: any = err;
      if (wrappedErr.response.status === 500) {
        return {
          errors: {
            "500":
              "There was an error creating the deal. Please make sure all fields are filled out correctly",
          },
        };
      }
      return { errors: wrappedErr.response.data };
    }
  }
);
