/* eslint-disable promise/no-return-wrap */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable promise/always-return */
/* eslint-disable no-param-reassign */
/* eslint-disable promise/catch-or-return */
import axios from "axios";
import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import { navigate } from "gatsby";

const baseURL = process.env.GATSBY_API_URL || "http://localhost:8000";

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  async (config): Promise<any> =>
    new Promise(resolve => {
      Auth.currentSession()
        .then(session => {
          const idTokenExpire = session.getAccessToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then(res => {
              res.refreshSession(
                refreshToken,
                (
                  err: any,
                  data: {
                    getAccessToken: () => {
                      (): any;
                      new (): any;
                      getJwtToken: { (): any; new (): any };
                    };
                  }
                ) => {
                  if (err) {
                    Sentry.captureException(err);
                    Auth.signOut().then(() => {
                      localStorage.removeItem("me");
                      // navigate them to a 404 page.
                      navigate("/404", {
                        state: {
                          error:
                            "There was an error with your authentication. Please contact a Clever representative at 833-7-CLEVER",
                        },
                      });
                    });
                  } else {
                    if (config && config.headers) {
                      config.headers.Authorization = `Bearer ${data
                        .getAccessToken()
                        .getJwtToken()}`;
                    }
                    resolve(config);
                  }
                }
              );
            });
          } else {
            if (config && config.headers) {
              config.headers.Authorization = `Bearer ${session
                .getAccessToken()
                .getJwtToken()}`;
            }
            resolve(config);
          }
        })
        .catch(e => {
          Sentry.captureException(e);
          Auth.signOut().then(() => {
            localStorage.removeItem("me");
            // navigate them to a 404 page
            navigate("/404", {
              state: {
                error:
                  "There was an error with your authentication. Please contact a Clever representative at 833-7-CLEVER",
              },
            });
          });
          // No logged-in user: don't set auth header
          // TODO: send them to the login gateway
          resolve(config);
        });
    })
);

export default instance;
