// contacts/magic-link/${magicUuid}
// const { access, refresh } = response.data;
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/gatsby";
import instance from "../../utils/Axios";

// //
// Async Thunk Actions
// //

export interface SurveyData {
  deal_id: string | number;
  data: any;
}

export const submitSurvey = createAsyncThunk<any, SurveyData>(
  "surveys/submitSurvey",
  async data => {
    try {
      const response = await instance.post(
        `/deals/hubspot-deal-survey/${data.deal_id}/`,
        {
          ...data.data,
        }
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: true,
        data: "Sorry! There was either an invalid field or missing field. Please try again.",
      };
    }
  }
);
