import { createSlice } from "@reduxjs/toolkit";
import {
  DataSenderDataModel,
  DataSenderModel,
  DataSenderObject,
  getAllDataSenders,
  getCustomerDataSender,
  getCustomerDataSenderData,
  getDealsFromContact,
  getMPUpdateOptions,
  postMPUpdate,
  sendCustomerDataSender,
  sendData,
} from "./actions";
import { Deal } from "../../Interfaces/deal";

const initialState = {
  loading: false,
  hasErrors: false,
  errors: null,
  successfullySentData: false,
  contactDeals: [] as Deal[],
  allDataSenders: null as DataSenderObject | null,
  dataSenderObject: null as DataSenderModel | null,
  dataSenderData: null as DataSenderDataModel | null,
  mpOptions: [] as string[],
  successfullyPostedMPUpdate: false,
};

const dataSenderSlice = createSlice({
  name: "dataSender",
  initialState,
  reducers: {
    resetDataStates(state) {
      state.successfullySentData = false;
      state.successfullyPostedMPUpdate = false;
      state.errors = null;
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    },
    setDataSenderObject(state, { payload }) {
      state.dataSenderObject = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(sendData.pending, state => {
      state.loading = true;
    });
    builder.addCase(sendData.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
        state.successfullySentData = false;
      } else {
        state.hasErrors = false;
        state.successfullySentData = true;
      }
    });
    builder.addCase(sendData.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    builder.addCase(getCustomerDataSender.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCustomerDataSender.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
        state.successfullySentData = false;
      } else {
        state.hasErrors = false;
        state.dataSenderObject = payload;
      }
    });
    builder.addCase(getCustomerDataSender.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    builder.addCase(getCustomerDataSenderData.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getCustomerDataSenderData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.hasErrors = true;
          state.errors = payload.messages;
          state.successfullySentData = false;
        } else {
          state.hasErrors = false;
          state.dataSenderData = payload;
        }
      }
    );
    builder.addCase(getCustomerDataSenderData.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    builder.addCase(sendCustomerDataSender.pending, state => {
      state.loading = true;
    });
    builder.addCase(sendCustomerDataSender.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
        state.successfullySentData = false;
      } else {
        state.hasErrors = false;
        state.successfullySentData = true;
      }
    });
    builder.addCase(sendCustomerDataSender.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    // GET ALL DATA SENDERS
    builder.addCase(getAllDataSenders.pending, state => {
      state.loading = true;
    });
    builder.addCase(getAllDataSenders.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
        state.successfullySentData = false;
      } else {
        state.hasErrors = false;
        state.allDataSenders = payload;
      }
    });
    builder.addCase(getAllDataSenders.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    // GET ALL MP STATUSES
    builder.addCase(getMPUpdateOptions.pending, state => {
      state.loading = true;
    });
    builder.addCase(getMPUpdateOptions.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
      } else {
        state.hasErrors = false;
        state.mpOptions = payload;
      }
    });
    builder.addCase(getMPUpdateOptions.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    // POST MP UPDATE
    builder.addCase(postMPUpdate.pending, state => {
      state.loading = true;
    });
    builder.addCase(postMPUpdate.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
      } else {
        state.hasErrors = false;
        state.successfullyPostedMPUpdate = true;
      }
    });
    builder.addCase(postMPUpdate.rejected, state => {
      state.hasErrors = true;
      state.loading = false;
    });
    // GET CONTACT DEALS
    builder.addCase(getDealsFromContact.pending, state => {
      state.loading = true;
    });
    builder.addCase(getDealsFromContact.fulfilled, (state, { payload }) => {
      state.contactDeals = payload.results;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(getDealsFromContact.rejected, (state, { payload }: any) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.errors = payload.messages;
      }
    });
  },
});

export const { resetDataStates, setErrors, setDataSenderObject } =
  dataSenderSlice.actions;

export default dataSenderSlice.reducer;
