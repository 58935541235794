import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";

import magicLinksReducer from "./MagicLinks/reducer";
import dealsReducer from "./Deal/reducer";
import communicationsReducer from "./Communications/reducer";
import surveysReducer from "./Surveys/reducer";
import dataSenderReducer from "./DataSender/reducer";

const rootReducer = combineReducers({
  magicLinks: magicLinksReducer,
  communications: communicationsReducer,
  deals: dealsReducer,
  surveys: surveysReducer,
  dataSender: dataSenderReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
