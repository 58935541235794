/* eslint-disable max-depth */
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth, CognitoUser } from "@aws-amplify/auth";

// //
// Async Thunk Actions
// //

type GetTokensFromMagicLinkProps = {
  magicUuid: string | number;
};

const baseURL =
  process.env.NODE_ENV !== "production"
    ? "https://staging-api.listwithclever.com"
    : "https://api.listwithclever.com";

// eslint-disable-next-line @typescript-eslint/require-await
const setTokensAndAuthenticate = async (accessToken, idToken, refreshToken) => {
  // Fetch the current config
  const config = Auth.configure();
  // Set the user pool

  const userPool = new CognitoUserPool({
    UserPoolId: config.userPoolId as any,
    ClientId: config.userPoolWebClientId as any,
    Storage: new CookieStorage(config.cookieStorage as any),
  });
  // Set the id token
  const cognitoIdToken = new CognitoIdToken({
    IdToken: idToken,
  });

  // set the access token
  const cognitoAccessToken = new CognitoAccessToken({
    AccessToken: accessToken,
  });

  // set the refresh token
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });
  // get the "username" from the tokens
  const username = cognitoIdToken.payload.email;

  // set the cognito user
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
    Storage: new CookieStorage(config.cookieStorage as any),
  });
  // then sign in the user with the generated user session
  user.setSignInUserSession(
    new CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    })
  );
};

export const getTokensFromMagicLink = createAsyncThunk<
  MagicLink,
  GetTokensFromMagicLinkProps
>("magicLinks/getTokensFromMagicLink", async props => {
  try {
    const { magicUuid } = props;
    const url = `${baseURL}/contacts/magic-link/${magicUuid}/`;
    const response = await axios.get(url);
    const { access, refresh, id_token } = response.data;

    await setTokensAndAuthenticate(access, id_token, refresh);

    const session = await Auth.currentSession();
    if (session) {
      const accessTokenExpire = session.getAccessToken().getExpiration();
      const refreshToken = session.getRefreshToken();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      // if token has expired refresh it and set authed to true
      if (accessTokenExpire < currentTimeSeconds) {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        if (authenticatedUser) {
          await authenticatedUser.refreshSession(refreshToken);
        }
      }
    }

    // Testing link
    return response.data;
  } catch (e: any) {
    if (e.response) {
      throw new Error(e.response.status);
    }
    if (e.request) {
      throw new Error("No Response");
    }
    throw new Error(e.message);
  }
});

export const createNewMagicLink = createAsyncThunk<
  MagicLinkRequestResponse,
  MagicLinkRequestBody
>("magicLinks/createNewMagicLink", async (body, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${baseURL}/contacts/magic-link/`, body);
    return response.data;
  } catch (e: any) {
    if (e.response) {
      return rejectWithValue(e.response.data);
    }
    if (e.request) {
      throw new Error("No Response");
    }
    throw new Error("Something went wrong while creating magic link");
  }
});
